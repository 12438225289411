<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card actionable title="Dues Summary CFA/FRM" class="cardx">
          <vs-row type="flex" style="margin-top: 2%; margin-bottom: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <v-select
                label="cities"
                :options="cities"
                v-model="selected_city"
                disabled
                v-if="getRole('cm1')"
                style="width: 240px; z-index: 1000"
              ></v-select>

              <v-select
                label="cities"
                :options="cities"
                v-model="selected_city"
                v-if="
                  getRole('cm4') ||
                  getRole('ac1') ||
                  getRole('fe') ||
                  getRole('cmca') ||
                  getRole('baca') ||
                  getRole('hr')
                "
                style="width: 240px; z-index: 1000"
              ></v-select>
            </vs-col>
          </vs-row>
          
          <vs-row type="flex" style="margin-top: 4%; margin-bottom: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <h2>B2C</h2>
            </vs-col>
          </vs-row>
          <!-- CFA/FRM B2C table -->
          <vs-table
            multiple
            v-model="selected"
            max-items="status"
            :data="displayData"
          >
            <template slot="thead">
              <vs-th> STATUS </vs-th>
              <vs-th> YEAR </vs-th>
              <vs-th> CFA </vs-th>
              <vs-th> Count </vs-th>
              <vs-th> FRM </vs-th>
              <vs-th> Count </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td v-if="tr.year === 'Total'">
                  <b> {{ tr.status }} </b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.status }}
                </vs-td>

                <vs-td v-if="tr.year === 'Total'">
                  <b>{{ tr.year }}</b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.year }}
                </vs-td>

                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.cfaLeads,
                        tr.status,
                        `${tr.cfaacourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    style="padding-right: 50%;"
                    >{{ numberWithCommas(tr.cfa) }}</a
                  >
                </vs-td>
                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.cfaLeads,
                        tr.status,
                        `${tr.cfacourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    style="padding-right: 60%;"
                  >{{ tr.cfaLeads.length }}</a>
                </vs-td>
                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.frmLeads,
                        tr.status,
                        `${tr.frmcourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    style="padding-right: 54%;"
                    >{{ numberWithCommas(tr.frm) }}</a
                  >
                </vs-td>
                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.frmLeads,
                        tr.status,
                        `${tr.frmcourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                     style="padding-right: 60%;"
                    >
                  {{ tr.frmLeads.length }}</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          
          <vs-row type="flex" style="margin-top: 4%; margin-bottom: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <h2>B2B</h2>
            </vs-col>
          </vs-row>
          <!--CfA/FRM B2B table -->
          <vs-table
            multiple
            v-model="selected"
            max-items="status"
            :data="displayData2"
          >
            <template slot="thead">
              <vs-th> STATUS </vs-th>
              <vs-th> YEAR </vs-th>
              <vs-th> CFA </vs-th>
              <vs-th> Count </vs-th>
              <vs-th> FRM </vs-th>
              <vs-th> Count </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td v-if="tr.year === 'Total'">
                  <b> {{ tr.status }} </b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.status }}
                </vs-td>

                <vs-td v-if="tr.year === 'Total'">
                  <b>{{ tr.year }}</b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.year }}
                </vs-td>

                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.cfaLeads,
                        tr.status,
                        `${tr.cfacourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    style="padding-right: 50%;"
                    >{{ numberWithCommas(tr.cfa) }}</a
                  >
                </vs-td>
                <vs-td>
                  <a
                    @click="
                      displayIndividualData(
                        tr.cfaLeads,
                        tr.status,
                        `${tr.cfacourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    >{{ tr.cfaLeads.length }}</a>
                </vs-td>
                <vs-td style="text-align: right;">
                  <a
                    @click="
                      displayIndividualData(
                        tr.frmLeads,
                        tr.status,
                        `${tr.frmcourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    style="padding-right: 50%;"
                    >{{  numberWithCommas(tr.frm) }}</a
                  >
                </vs-td>
                <vs-td>
                 <a
                    @click="
                      displayIndividualData(
                        tr.frmLeads,
                        tr.status,
                        `${tr.frmcourse} - ${selected_city} - ${tr.status} - ${tr.year}`
                      )
                    "
                    > {{ tr.frmLeads.length }}</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </template>
    <DuesSummaryPopup></DuesSummaryPopup>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import eventbus from "../components/eventbus";
import DuesSummaryPopup from "../components/pagesComponents/DuesSummaryPopup.vue";
import moment from "moment";
export default {
  data() {
    return {
      selected_course: "CFA/FRM",
      retailsEnabled: true,
      corporateEnabled: true,
      selected: "",
      selected_city: "",
      displayData: [],
      displayData2: [],
      loggedin: "",
      all_spocs: [],
      all: [],
      cities: [
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Ernakulam",
        "Hyderabad",
        "Kolkata",
        "Mumbai",
        "Pune",
        "SHARK",
        "Pan India"
      ]
    };
  },
  components: {
    "v-select": vSelect,
    DuesSummaryPopup,
  },
  mounted() {
    eventbus.$on("reload-dues-data", () => {
      this.populateData(this.selected_course);
    });
    // this.cities = [];
    this.getSpocs();
  },
  watch: {
    selected_city: function () {
      this.populateData(this.selected_course);
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toLocaleString('en-IN');
    },
    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },
    getParamsObj(purchases) {
      let person_ids = [];
      purchases.forEach((purchase) => {
        person_ids.push(purchase.person_id);
      });
      let obj = {
        person_ids: person_ids.join(),
      };
      return obj;
    },
    displayIndividualData(data, status, title) {
      console.log(data, status, title, "due summary total passing pop up");
      eventbus.$emit("display-data-popup", [data, status, title]);
      // this.$vs.loading();
      // let obj = this.getParamsObj(data);
      // let url = `${constants.ADMIN_SERVER_API}getSpocNameForDues`;
      // axios
      //   .get(url, {
      //     params: obj,
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
      //   .then((response) => {
      //     response.data.forEach((obj) => {
      //       data.forEach((purchase) => {
      //         if (parseInt(purchase.person_id) === parseInt(obj.person_id)) {
      //           purchase.spoc_name = obj.spoc_name;
      //         }
      //       });
      //     });
      //     // console.log(data);
      //     this.$vs.loading.close();
          
      //   })
      //   .catch((error) => {
      //     this.$vs.loading.close();
      //     this.handleError(error);
      //   });
      //
    },
    getSpocs() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.all_spocs = response.data.spocs;
          this.extractSpocByCity();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity() {
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac1"
      ) {
        this.selected_city = this.cities[0];
      } else if (localStorage.getItem("role") === "cm1") {
        this.all_spocs.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
      //   this.selected_city = this.cities[0];
      // this.cities.push("Pan India")
      // console.log("this.cities",this.cities)
    },
    populateData(selected_course) {
      this.$vs.loading();
      let params = {
        city: this.selected_city,
        courses: selected_course,
      };
      axios
        .get(`https://admin.milesforce.com/api/getOverDueData`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("getOverDueData", response.data)
          if (response.data.status !== "success") {
            this.handleNotification(response);
          }
          this.generateB2BAndB2CTables(response.data.purchases);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    generateB2BAndB2CTables(purchases) {
      console.log("generateB2BAndB2CTables2", purchases)
      let corporatePurchases = [];
      let nonCorporatePurchases = [];

      purchases.forEach((purchase) => {
        if(purchase.batch != null){ 
           if (purchase.batch.includes("EE") && purchase.b2b_self_sponsor !== 1) {
               corporatePurchases.push(purchase);
           }else {
             nonCorporatePurchases.push(purchase);
           }
        }else {
           nonCorporatePurchases.push(purchase);
        }
      });

      this.displayData = this.calculateData(nonCorporatePurchases);
      this.displayData2 = this.calculateData(corporatePurchases);
    },
    calculateData(purchases) {
      let displayData = [];

      let totalYears = this.getIndividualYearsFromPurchases(purchases);

      totalYears.forEach((year) => {
        var currentYear = moment().year();

        if (year === currentYear.toString()) {
          let overDueObj = this.getCurrentYearOverdueObj(purchases);
          let dueObj = this.getCurentYearDueObj(purchases);
          // console.log("data", dueObj)
          displayData.push(overDueObj);
          displayData.push(dueObj);
          return;
        }
           let obj = {
            status: "Overdue",
            year: year,
            cfacourse: "CFA",
            frmcourse: "FRM",
            cfa: this.getOverdueAmount(year, "CFA", purchases),
            frm: this.getOverdueAmount(year, "FRM", purchases),
            additional_purchases: this.getOverdueAmount(
              year,
              "New Purchase",
              purchases
            ),
            cfaLeads: this.getOverdueLeads(year, "CFA", purchases),
            frmLeads: this.getOverdueLeads(year, "FRM", purchases),
            additional_purchases_leads: this.getOverdueLeads(
              year,
              "New Purchase",
              purchases
            ),
          };
          
          if (obj.cfa !== 0 || obj.frm !== 0) {
            displayData.push(obj);
          }
        
      });

      let loanObj = this.getLoanObj(purchases);

      displayData.push(loanObj);

      let totalDataObj = this.getTotalDataObj(displayData);

      displayData.push(totalDataObj);

      let defaultersObj = this.getDefaultersObject(purchases);

      displayData.push(defaultersObj);

      let negativeFiguresObj = this.getnegativeFeaguresObj(purchases);

      displayData.push(negativeFiguresObj);

      // let amountInProcessObj = this.getAmountInProcessObj(purchases);

      // displayData.push(amountInProcessObj);
      console.log("display data",displayData)
      return displayData;
    },
    getnegativeFeaguresObj(purchases) {
      let obj = {
          status: "Incorrect Fees",
          year: "Incorrect Fees",
          cfa: 0,
          frm: 0,
          cfacourse: "CFA",
          frmcourse: "FRM",
          additional_purchases: 0,
          cfaLeads: [],
          frmLeads: [],
          additional_purchases_leads: [],
        };
      
      purchases.forEach((purchase) => {
        if (
          Math.sign(purchase.amount_pending) === -1 &&
          purchase.defaulter !== 1 &&
          purchase.loan === 0
        ) {
          if (purchase.name == "CFA") {
            if(purchase.agreed_cost<this.sumOfAmount(purchase)) {
              
              obj.cfa = obj.cfa + (purchase.agreed_cost - (purchase.amount_paid + purchase.amount_in_process));
              obj.cfaLeads.push(purchase);
              
            }
          } else if (purchase.name == "FRM") {
            if(purchase.agreed_cost<this.sumOfAmount(purchase)) {
              
              obj.frm = obj.frm + (purchase.agreed_cost - (purchase.amount_paid + purchase.amount_in_process));
              obj.frmLeads.push(purchase);
              
            }
          }
        }
      });

      return obj;
    },

    getTotalDataObj(displayData) {
       let obj = {
          status: "Total",
          year: "Total",
          cfa: 0,
          frm: 0,
          cfacourse: "CFA",
          frmcourse: "FRM",
          additional_purchases: 0,
          cfaLeads: [],
          frmLeads: [],
          additional_purchases_leads: [],
        };

        displayData.forEach((data) => {
          obj.cfa = obj.cfa + data.cfa;
          obj.frm = obj.frm + data.frm;
          obj.additional_purchases =
            obj.additional_purchases + data.additional_purchases;

          obj.cfaLeads = obj.cfaLeads.concat(data.cfaLeads);
          obj.frmLeads = obj.frmLeads.concat(data.frmLeads);
          obj.additional_purchases_leads = obj.additional_purchases_leads.concat(
            data.additional_purchases_leads
          );
        });
      
      return obj;
    },
    getDefaultersObject(purchases) {
      let obj = {
        status: "Defaulter",
        year: "Defaulter",
        cfa: 0,
        frm: 0,
        cfacourse: "CFA",
        frmcourse: "FRM",
        additional_purchases: 0,
        cfaLeads: [],
        frmLeads: [],
        additional_purchases_leads: [],
      };
      purchases.forEach((lead) => {
          if (
            Math.sign(lead.amount_pending) === 1 &&
            lead.defaulter === 1
          ) {
            if (lead.name == "CFA") {
              if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
                obj.cfa = obj.cfa + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
                obj.cfaLeads.push(lead);
              }
            } else if (lead.name == "FRM") {
              if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
                obj.frm = obj.frm + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
                obj.frmLeads.push(lead);
              }
            }else if (lead.name == "New Purchase") {
              if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
                obj.additional_purchases =
                  obj.additional_purchases + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
                obj.additional_purchases_leads.push(lead);
              }
            }
          }
      });

      return obj;
    },
    getCurrentYearOverdueObj(purchases) {
      var currentYear = moment().year();
      console.log(currentYear);
      // var currentMonth = moment().month();
      let leads = [];

      purchases.forEach((purchase) => {
        if (
          moment(this.unixTimestampConvert(purchase.enrollment_date)).year() ===
            currentYear &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          // console.log("leads", moment(this.unixTimestampConvert(purchase.enrollment_date)).format("YYYY-MM-DD"));
          let formatted = moment(
            this.unixTimestampConvert(purchase.enrollment_date)
          ).format("YYYY-MM-DD");

          let todayFormat = moment();
          if (todayFormat.diff(formatted, "days") > 30) {
            leads.push(purchase);
          }
        }
      });
      
       let obj = {
        status: "Overdue",
        year: currentYear,
        cfa: 0,
        frm: 0,
        cfacourse: "CFA",
        frmcourse: "FRM",
        cfaLeads: [],
        frmLeads: [],
        additional_purchases: 0,
        additional_purchases_leads: [],
      };
      
      leads.forEach((lead) => {
        if (Math.sign(lead.amount_pending) === 1) {
          if (lead.name == "CFA") {
            if(lead.agreed_cost>this.sumOfAmount(lead)) {
              obj.cfa = obj.cfa + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
              obj.cfaLeads.push(lead);
            }
            
          } else if (lead.name == "FRM") {
            if(lead.agreed_cost>this.sumOfAmount(lead)) {
              obj.frm = obj.frm + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
              obj.frmLeads.push(lead);
            }
            
          }else if (lead.name == "New Purchase") {
            obj.additional_purchases =
              obj.additional_purchases + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
            obj.additional_purchases_leads.push(lead);
          }
        }
      });

      return obj;
    },
    // unixTimestampConvert(value) {
    //   var dateString = moment.unix(value).format("DD/MM/YYYY");
    //   switch (dateString) {
    //     case "01/01/1970":
    //       return "";
    //     default:
    //       return dateString;
    //   }
    // },
    sumOfAmount(data) {
			// console.log("amount", data)
			// var msgTotal = data.reduce(function(prev, cur) {
				
			// }, 0);
      var msgTotal = data.amount_paid + data.amount_in_process;
			// console.log('Total Messages:', msgTotal);
			return msgTotal;
		},
    getCurentYearDueObj(purchases) {
      
      var currentYear = moment().year();
      // var currentMonth = moment().month();
      let leads = [];

      purchases.forEach((purchase) => {
        
        if (
          moment(this.unixTimestampConvert(purchase.enrollment_date)).year() === currentYear &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          let formatted = moment(
            this.unixTimestampConvert(purchase.enrollment_date)
          ).format("YYYY-MM-DD");
          
          let todayFormat = moment();
          if (todayFormat.diff(formatted, "days") <= 30) {
            
            leads.push(purchase);
            
          }
        }
      });
       
      let obj = {
        status: "Due",
        year: currentYear,
        cfa: 0,
        frm: 0,
        cfacourse: "CFA",
          frmcourse: "FRM",
        cfaLeads: [],
        frmLeads: [],
        additional_purchases: 0,
        additional_purchases_leads: [],
      };
      
      leads.forEach((lead) => {
        if (Math.sign(lead.agreed_cost - (lead.amount_paid + lead.amount_in_process)) === 1) {
          if (lead.name == "CFA") {
            if(lead.agreed_cost>this.sumOfAmount(lead)) {
              if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
                obj.cfa = obj.cfa + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
                obj.cfaLeads.push(lead);
              }
            }
          } else if (lead.name == "FRM") {
            if(lead.agreed_cost>this.sumOfAmount(lead)) {
              if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
                obj.frm = obj.frm + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
                obj.frmLeads.push(lead);
              }
            }
          }else if (lead.name == "New Purchase") {
            if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
              obj.additional_purchases =
                obj.additional_purchases + (lead.agreed_cost - (lead.amount_paid + lead.amount_in_process));
              obj.additional_purchases_leads.push(lead);
            }
          }
        }
        
      });

      return obj;
    },
    getLoanObj(purchases) {
     
      let obj = {
          status: "Loan",
          year: "Loan",
          cfa: 0,
          frm: 0,
          cfacourse: "CFA",
          frmcourse: "FRM",
          additional_purchases: 0,
          cfaLeads: [],
          frmLeads: [],
          additional_purchases_leads: [],
        };
      
      purchases.forEach((purchase) => {
          if (
            // purchase.loan === 1 &&
            // purchase.defaulter === 1 &&
            // Math.sign(purchase.amount_pending) === 1
            purchase.loan === 1 &&
            purchase.defaulter === 0 &&
            parseInt(purchase.amount_pending) -
              parseInt(purchase.amount_in_process) !==
              0
          ) {
            if (purchase.name == "CFA") {
              if(this.numberWithCommas(parseInt(purchase.agreed_cost) - this.sumOfAmount(purchase))!= 0){
                obj.cfa = obj.cfa + (purchase.agreed_cost - (purchase.amount_paid + purchase.amount_in_process));
                obj.cfaLeads.push(purchase);
              }
            } else if (purchase.name == "FRM") {
              if(this.numberWithCommas(parseInt(purchase.agreed_cost) - this.sumOfAmount(purchase))!= 0){
                obj.frm = obj.frm + (purchase.agreed_cost - (purchase.amount_paid + purchase.amount_in_process));
                obj.frmLeads.push(purchase);
              }
            }
          }
      });
      
      return obj;
    },
    getOverdueAmount(year, course, purchases) {
      let leads = [];
      purchases.forEach((purchase) => {
        if (
          purchase.created_at_year.toString() === year &&
          purchase.name === course &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          leads.push(purchase);
        }
      });

      let finalNumber = 0;

      leads.forEach((lead) => {
      if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
        if (Math.sign(lead.amount_pending) === 1) {
          finalNumber = finalNumber + (lead.agreed_cost -(lead.amount_paid + lead.amount_in_process));
        }
      }
      });
      console.log('final number',finalNumber);
      return finalNumber;
    },
    getOverdueLeads(year, course, purchases) {
      
      let leads = [];
      purchases.forEach((purchase) => {
        // if(this.numberWithCommas(parseInt(purchase.agreed_cost) - this.sumOfAmount(purchase))!= 0){
        // console.log(purchase)
          if (
            purchase.created_at_year.toString() === year &&
            purchase.name === course &&
            purchase.defaulter === 0 &&
            purchase.loan === 0
          ) {
            leads.push(purchase);
          }
        // }
      });

      let finalList = [];
      leads.forEach((lead) => {
        if(this.numberWithCommas(parseInt(lead.agreed_cost) - this.sumOfAmount(lead))!= 0){
        if (Math.sign(lead.agreed_cost -(lead.amount_paid + lead.amount_in_process)) === 1) {
          console.log("Lead", lead);
          finalList.push(lead);
        }
        }
      });
      return finalList;
    },
    getIndividualYearsFromPurchases(purchases) {
      purchases.forEach((purchase) => {
        purchase.created_at_year = moment(
          purchase.created_at,
          "YYYY-MM-DD hh:mm:ss"
        ).year();
      });
      var unique = purchases
        .map((name) => {
          return {
            count: 1,
            name: name.created_at_year,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let years = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          years.push(key);
        }
      }
      console.log('years',years);
      return years;
    },
  },
};
</script>
<style></style>
