var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-row"},[[_c('div',[_c('vx-card',{staticClass:"cardx",attrs:{"actionable":"","title":"Dues Summary CFA/FRM"}},[_c('vs-row',{staticStyle:{"margin-top":"2%","margin-bottom":"2%"},attrs:{"type":"flex"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"4"}},[(_vm.getRole('cm1'))?_c('v-select',{staticStyle:{"width":"240px","z-index":"1000"},attrs:{"label":"cities","options":_vm.cities,"disabled":""},model:{value:(_vm.selected_city),callback:function ($$v) {_vm.selected_city=$$v},expression:"selected_city"}}):_vm._e(),(
                _vm.getRole('cm4') ||
                _vm.getRole('ac1') ||
                _vm.getRole('fe') ||
                _vm.getRole('cmca') ||
                _vm.getRole('baca') ||
                _vm.getRole('hr')
              )?_c('v-select',{staticStyle:{"width":"240px","z-index":"1000"},attrs:{"label":"cities","options":_vm.cities},model:{value:(_vm.selected_city),callback:function ($$v) {_vm.selected_city=$$v},expression:"selected_city"}}):_vm._e()],1)],1),_c('vs-row',{staticStyle:{"margin-top":"4%","margin-bottom":"2%"},attrs:{"type":"flex"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('h2',[_vm._v("B2C")])])],1),_c('vs-table',{attrs:{"multiple":"","max-items":"status","data":_vm.displayData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[(tr.year === 'Total')?_c('vs-td',[_c('b',[_vm._v(" "+_vm._s(tr.status)+" ")])]):_c('vs-td',[_vm._v("\n                "+_vm._s(tr.status)+"\n              ")]),(tr.year === 'Total')?_c('vs-td',[_c('b',[_vm._v(_vm._s(tr.year))])]):_c('vs-td',[_vm._v("\n                "+_vm._s(tr.year)+"\n              ")]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"50%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.cfaLeads,
                      tr.status,
                      ((tr.cfaacourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(_vm.numberWithCommas(tr.cfa)))])]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"60%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.cfaLeads,
                      tr.status,
                      ((tr.cfacourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(tr.cfaLeads.length))])]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"54%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.frmLeads,
                      tr.status,
                      ((tr.frmcourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(_vm.numberWithCommas(tr.frm)))])]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"60%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.frmLeads,
                      tr.status,
                      ((tr.frmcourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v("\n                "+_vm._s(tr.frmLeads.length))])])],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" STATUS ")]),_c('vs-th',[_vm._v(" YEAR ")]),_c('vs-th',[_vm._v(" CFA ")]),_c('vs-th',[_vm._v(" Count ")]),_c('vs-th',[_vm._v(" FRM ")]),_c('vs-th',[_vm._v(" Count ")])],1)],2),_c('vs-row',{staticStyle:{"margin-top":"4%","margin-bottom":"2%"},attrs:{"type":"flex"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('h2',[_vm._v("B2B")])])],1),_c('vs-table',{attrs:{"multiple":"","max-items":"status","data":_vm.displayData2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[(tr.year === 'Total')?_c('vs-td',[_c('b',[_vm._v(" "+_vm._s(tr.status)+" ")])]):_c('vs-td',[_vm._v("\n                "+_vm._s(tr.status)+"\n              ")]),(tr.year === 'Total')?_c('vs-td',[_c('b',[_vm._v(_vm._s(tr.year))])]):_c('vs-td',[_vm._v("\n                "+_vm._s(tr.year)+"\n              ")]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"50%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.cfaLeads,
                      tr.status,
                      ((tr.cfacourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(_vm.numberWithCommas(tr.cfa)))])]),_c('vs-td',[_c('a',{on:{"click":function($event){return _vm.displayIndividualData(
                      tr.cfaLeads,
                      tr.status,
                      ((tr.cfacourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(tr.cfaLeads.length))])]),_c('vs-td',{staticStyle:{"text-align":"right"}},[_c('a',{staticStyle:{"padding-right":"50%"},on:{"click":function($event){return _vm.displayIndividualData(
                      tr.frmLeads,
                      tr.status,
                      ((tr.frmcourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(_vm._s(_vm.numberWithCommas(tr.frm)))])]),_c('vs-td',[_c('a',{on:{"click":function($event){return _vm.displayIndividualData(
                      tr.frmLeads,
                      tr.status,
                      ((tr.frmcourse) + " - " + _vm.selected_city + " - " + (tr.status) + " - " + (tr.year))
                    )}}},[_vm._v(" "+_vm._s(tr.frmLeads.length))])])],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" STATUS ")]),_c('vs-th',[_vm._v(" YEAR ")]),_c('vs-th',[_vm._v(" CFA ")]),_c('vs-th',[_vm._v(" Count ")]),_c('vs-th',[_vm._v(" FRM ")]),_c('vs-th',[_vm._v(" Count ")])],1)],2)],1)],1)],_c('DuesSummaryPopup')],2)}
var staticRenderFns = []

export { render, staticRenderFns }